// ImageDetails.js
import React from 'react';
import x_icon from "../../resources/x_icon.png";

const ImageInfoModalHeader = ({selectedImage, setShowModal, setSelectedImage}) => {

    const handleCloseButton = () => {
        setShowModal(false)
        setSelectedImage(null)
    }
    return (
        <div style={{paddingRight: "5px", paddingLeft: "5px", position: "relative"}}>
            <h1 id="title">{selectedImage && selectedImage.Title}</h1>

            <div className="flex-row d-flex mb-1"
                 style={{alignItems: "flex-end", justifyContent: "space-between"}}>
                <div className="year-runtime-wrapper flex-row d-flex">
                    <div id="year"
                         className="text-info"
                         style={{marginRight: "15px"}}
                    >{selectedImage && selectedImage.Year}</div>
                    <div id="runtime"
                         className="text-info"> {selectedImage && selectedImage.Runtime}</div>
                </div>

                <div className="d-flex flex-column">
                    <h6 className="mb-0 text-warning align-self-end">IMDb Rating</h6>
                    <h6 className="mt-1 mb-0 align-self-end">
                        <i className="fa-solid fa-star"></i>
                        <div
                            id="rating"> {(selectedImage && selectedImage.Ratings && selectedImage.Ratings[0].Value) && selectedImage.Ratings[0].Value} </div>
                    </h6>
                </div>
            </div>
            <div className="round-button" onClick={handleCloseButton}>
                <img src={x_icon} alt="x"/>
            </div>
        </div>
    );
};

export default ImageInfoModalHeader;
