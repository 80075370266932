import React from "react";
import '../App.scss';
import ImageGallery from "./ImageGallery";
import SearchResultDisplay from "./SearchResultDisplay";

const Body = (props) => {
    return (
        <>
            {props.searchResults.length > 0 && <div className="section">
                <div className="section-container">
                    <h3 className="h3 section-title">Your Search Result</h3>
                    <SearchResultDisplay searchResults={props.searchResults}/>
                </div>
            </div>}

            {props.isLoggedIn &&
                <div className="section section-bg2">
                    <div className="section-container">
                        <h3 className="h3 section-title">Your Watchlist</h3>
                        <ImageGallery property={"watchlist"}/>
                    </div>
                </div>}
            <div className="section section-bg2">
                <div className="section-container">
                    <h3 className="h3 section-title mb-0">Featured Today</h3>
                    <ImageGallery property={"featured"}/>
                </div>
            </div>
            <div className="section">
                <div className="section-container">
                    <h3 className="h3 section-title">Popular Movies</h3>
                    <ImageGallery property={"popular-movies"}/>
                </div>
            </div>
            {/*<div className="section">
                <div className="section-container">
                    <h3 className="h3 section-title">Popular Series</h3>
                    <ImageGallery property={"popular-series"}/>
                </div>
            </div>
            <div className="section section-bg2">
                <div className="section-container">
                    <h3 className="h3 section-title">Upcoming Movies</h3>
                    <ImageGallery property={"upcoming"}/>
                </div>
            </div>*/}
        </>
    );
};

export default Body;