import './App.scss';
import React, {useState} from "react";
import Header from "./components/Header/Header";
import Body from "./components/Body";

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [searchResults, setSearchResults] = useState([])

    return (
        <div>
            <Header
                className="header"
                setIsLoggedIn={setIsLoggedIn}
                isLoggedIn={isLoggedIn}
                setSearchResults={setSearchResults}
                searchResults={searchResults}
            />
            <Body
                setIsLoggedIn={setIsLoggedIn}
                isLoggedIn={isLoggedIn}
                searchResults={searchResults}
            />
        </div>
    );
}

export default App;
