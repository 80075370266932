import * as React from 'react';
import {useState} from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

export default function ProfileMenu(props) {
    const [show, setShow] = useState(false);
    const showDropdown = () => {
        setShow(!show);
    }
    const hideDropdown = () => {
        setShow(false);
    }

    return (
        <div>
            <DropdownButton id="profile-basic-menu" title={"Profile"} show={show}
                            onMouseEnter={showDropdown}
                            onMouseLeave={hideDropdown}>
                <Dropdown.Item id="profile-basic-button">My account</Dropdown.Item>
                <Dropdown.Item id="profile-basic-button">Settings</Dropdown.Item>
                <Dropdown.Item id="profile-basic-button">Watchlist</Dropdown.Item>
                <Dropdown.Item id="profile-basic-button" onClick={props.handleLogout}>Logout</Dropdown.Item>
            </DropdownButton>
        </div>
    );
}