import React from "react";
import Modal from "react-bootstrap/Modal";
import ImageInfoModalHeader from "./ImageInfoModalHeader";
import ImageInfoModalBody from "./ImageInfoModalBody";

const ImageModal = ({showModal, setShowModal, setSelectedImage, selectedImage}) => {
    return (
        <Modal show={showModal} onHide={() => {
            setShowModal(false)
            setSelectedImage(null)
        }}
        >
            <div className="flex-column d-flex modal-background" id="mediaInfoModal">
                {/* Title, Year, Runtime Rating div*/}
                <ImageInfoModalHeader selectedImage={selectedImage} setShowModal={setShowModal}
                                      setSelectedImage={setSelectedImage}/>

                {/* Image and Information Card */}
                <ImageInfoModalBody selectedImage={selectedImage}/>

            </div>
        </Modal>
    );
};

export default ImageModal;
