// MovieDetails.js
import React, {useEffect, useState} from 'react';

const ImageInfoModalBody = ({selectedImage}) => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const checkScreenSize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
    };

    useEffect(() => {
        setIsSmallScreen(window.innerWidth <= 768);
        window.addEventListener('resize', checkScreenSize);
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    return (
        <div className="card flex-row d-flex">
            {!isSmallScreen && <div>
                <img id="poster" className="img-fluid rounded-start"
                     src={selectedImage && selectedImage.Poster}
                     alt="Movie Poster"/>
            </div>}

            <div className="col-md-8 card-body d-flex flex-column">
                <h5 className="card-title" id="title">Plot</h5>
                <p className="card-text" id="plot"> {selectedImage && selectedImage.Plot}</p>

                <p className="info-type">
                    Directors:
                    <span id="director-names"
                          className="information"> {selectedImage && selectedImage.Director}</span>
                </p>
                <p className="info-type">
                    Cast:
                    <span id="cast-names"
                          className="information"> {selectedImage && selectedImage.Actors}</span>
                </p>
                <p className="info-type">
                    Genre:
                    <span id="genre"
                          className="information"> {selectedImage && selectedImage.Genre}</span>
                </p>
            </div>
        </div>
    );
};

export default ImageInfoModalBody;
