import React, {useState} from "react";
import '../App.scss';
import placeholder from "../resources/Error404_1.jpg"
import ImageModal from "./mediaInfoModal/ImageInfoModal";

const SearchResultDisplay = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = async (item) => {
        let imageInfo = await getDetailedMediaInformation(item.title, item.type);
        if (imageInfo === undefined || imageInfo === null) {
            //add: No Info for this Media Type available!
        } else {
            setSelectedImage(imageInfo);
            setShowModal(true)
        }
    };

    async function getDetailedMediaInformation(title, type) {
        const url = `https://www.omdbapi.com/?t=${title}&type=${type}&apikey=d19cd846`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.Response !== "False" && data) {
                return data;
            }
        } catch (err) {
            console.log(err);
        }
    }

    let imageUrls = [];

    if (props.searchResults && props.searchResults.length > 0) {
        imageUrls = props.searchResults.map((item) => {
            return {
                url: item.Poster !== "N/A" ? item.Poster : placeholder,
                type: item.Type === "game" ? "videogame" : item.Type,
                title: item.Title,
                year: item.Year,
                rating: item.Ratings !== null && item.Ratings !== undefined ? item.Ratings[0].Value : 0,
                runtime: item.Runtime,
                plot: item.Plot,
                directors: item.Director,
                cast: item.Actors,
                genre: item.Genre,
                poster: item.Poster
            };
        });
    } else {
        console.log("No search results");
    }

    let ImageElements = imageUrls.map((item, index) => (
        <div key={index} className={`image-wrapper ${item.type}`}>
            <span className="image-type">{item.type}</span>
            <img
                src={item.url}
                alt={`${index}`}
                className="image-card"
                onClick={() => handleImageClick(item)}
            />
        </div>
    ));

    return (
        <div>
            <div className="card-container">
                {ImageElements}
            </div>
            <ImageModal showModal={showModal} setShowModal={setShowModal} setSelectedImage={setSelectedImage}
                        selectedImage={selectedImage}/>
        </div>
    );
};

export default SearchResultDisplay;
