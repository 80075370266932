import React, {useEffect, useRef, useState} from "react";
import {Button} from "@mui/material";
import siteLogo from '../../resources/imdd_icon.png'
import SearchBar from "./SearchBar";
import '../../App.scss';
import Keycloak from "keycloak-js";
import ProfileMenu from "./ProfileMenu";

const keycloak = new Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM,
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT,
});

const Header = (props) => {
    const isRun = useRef(false);
    const [jwt, setJwt] = useState("")
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const checkScreenSize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
    };

    useEffect(() => {
        setIsSmallScreen(window.innerWidth <= 768);
        window.addEventListener('resize', checkScreenSize);
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    const handleSignIn = async () => {
        if (!isRun.current) {
            isRun.current = true;
            await keycloak
                .init({
                    onLoad: "login-required",
                    checkLoginIframe: false,
                })
                .then((authenticated) => {
                    console.log("--> log: authenticated ", authenticated);
                    props.setIsLoggedIn(authenticated);
                    setJwt(keycloak.token);
                }).catch((error) => {
                    console.log("--> log: logout error ", error);
                });
        }
    }

    const handleLogout = () => {
        keycloak.logout().then((authenticated) => {
            props.setIsLoggedIn(authenticated)
            setJwt("")
        }).catch((error) => {
            console.log("--> log: logout error ", error);
        });
    }

    return (
        <>
            <>
                <div className="header">
                    <div className="d-flex flex-row align-items-center">
                        <img src={siteLogo} alt="Home"/>
                        {/*!isSmallScreen && <BasicMenu/>*/}
                    </div>
                    <SearchBar
                        setSearchResults={props.setSearchResults}
                        searchResults={props.searchResults}
                    />
                    {props.isLoggedIn ?
                        <ProfileMenu className="sign-in" handleLogout={handleLogout}>Profile</ProfileMenu> :
                        <Button onClick={handleSignIn} className="sign-in">Sign In</Button>}
                </div>
            </>
        </>
    );
};

export default Header;