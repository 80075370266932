import React, {useEffect, useState} from 'react';
import {FormControl, IconButton, InputBase, MenuItem, Select} from '@mui/material';
import '../../App.scss';
import searchIcon from '../../resources/search_icon.png'


const SearchBar = (props) => {

    const [searchBarFilter, setSearchBarFilter] = useState('All');
    const [apiTypeFilter, setApiTypeFilter] = useState('');
    const [userInput, setUserInput] = useState('');
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const checkScreenSize = () => {
        setIsSmallScreen(window.innerWidth <= 768);
    };

    useEffect(() => {
        setIsSmallScreen(window.innerWidth <= 768);
        window.addEventListener('resize', checkScreenSize);
        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (userInput.length >= 3) {
                searchMediaByKeyword(userInput);
            }
        }, 200);
        return () => clearTimeout(timer);
    }, [userInput, searchBarFilter]);

    const handleChange = (event) => {
        setSearchBarFilter(event.target.value);
        if (event.target.value === 'Series') {
            setApiTypeFilter('series')
        }
        if (event.target.value === 'Movies') {
            setApiTypeFilter('movie')
        }
        if (event.target.value === 'Games') {
            setApiTypeFilter('game')
        }
        if (event.target.value === 'All') {
            setApiTypeFilter('')
        }
    };

    // Fetches data from api and calls function to add it in
    async function searchMediaByKeyword(search) {
        const url = `https://www.omdbapi.com/?s=${search}&type=${apiTypeFilter}&apikey=d19cd846`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.Response !== "False" && data.Search) {
                props.setSearchResults(data.Search);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleSearch = (event) => {
        setUserInput(event.target.value)
        if (event.target.value.length <= 1) props.setSearchResults([]);
    }

    return (
        <div className="searchbar-style">
            {<FormControl style={{minWidth: "auto"}}>
                <Select
                    value={searchBarFilter}
                    onChange={handleChange}
                    disableUnderline={true}
                    variant="standard"
                    margin="dense"
                >
                    <MenuItem value="All"><>All</>
                    </MenuItem>
                    <MenuItem value="Movies">Movies</MenuItem>
                    <MenuItem value="Series">Series</MenuItem>
                    <MenuItem value="Games">Games</MenuItem>
                </Select>
            </FormControl>
            }
            <InputBase
                onChange={handleSearch}
                placeholder="Search in OMDb"
                sx={{flexGrow: 1, marginLeft: "5px"}}
            />
            {!isSmallScreen && <IconButton type="submit" className="search-icon">
                <img src={searchIcon} alt="404"/>
            </IconButton>}
        </div>
    );
};

export default SearchBar;
