import React, {useEffect, useState} from "react";
import '../App.scss';
import placeholder from "../resources/Error404_1.jpg"
import ImageModal from "./mediaInfoModal/ImageInfoModal";

let imagePlaceholder = [
    {url: placeholder, type: 'movie'},
    {url: placeholder, type: 'series'},
];

const ImageGallery = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [mediaTopic, setMediaTopic] = useState("Sharks");
    const [apiTypeFilter, setApiTypeFilter] = useState("");
    const [imageURLs, setImageURLs] = useState([]);

    useEffect(() => {
        handleGalleryProperty();
    },);

    const handleImageClick = async (item) => {
        let imageInfo = await getDetailedMediaInformation(item.title, item.type);
        if (imageInfo === undefined || imageInfo === null) {
            //add: No Info for this Media Type available!
        } else {
            setSelectedImage(imageInfo);
            setShowModal(true)
        }
    };

    async function getDetailedMediaInformation(title, type) {
        const url = `https://www.omdbapi.com/?t=${title}&type=${type}&apikey=d19cd846`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.Response !== "False" && data) {
                return data;
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleGalleryProperty = () => {
        if (props.property === "watchlist") {
            // retrieve info from db for logged in user
        } else if (props.property === "featured") {
            setMediaTopic("Nature");
        } else if (props.property === "upcoming") {
            setMediaTopic("Cows");
        } else if (props.property === "popular-movies") {
            setMediaTopic("Bears");
            setApiTypeFilter("movie");
        } else if (props.property === "popular-series") {
            setMediaTopic("dogs");
            setApiTypeFilter("series");
        } else {
            setApiTypeFilter("");
        }
    };

    useEffect(() => {
        searchMediaByKeyword();
    }, [mediaTopic]);

    async function searchMediaByKeyword() {
        const url = `https://www.omdbapi.com/?s=${mediaTopic}&type=${apiTypeFilter}&apikey=d19cd846`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            if (data.Response !== "False" && data.Search) {
                setImageURLs(data.Search.map((item) => {
                    return {
                        url: item.Poster !== "N/A" ? item.Poster : placeholder,
                        type: item.Type === "game" ? "videogame" : item.Type,
                        title: item.Title,
                        year: item.Year,
                        rating: item.Ratings !== null && item.Ratings !== undefined ? item.Ratings[0].Value : 0,
                        runtime: item.Runtime,
                        plot: item.Plot,
                        directors: item.Director,
                        cast: item.Actors,
                        genre: item.Genre,
                        poster: item.Poster
                    };
                }));
            } else {
                setImageURLs(imagePlaceholder)
            }
        } catch (err) {
            console.log(err);
            setImageURLs(imagePlaceholder)
        }
    }

    const getImageTypeClass = (type) => {
        if (type === 'movie') {
            return 'movie';
        } else if (type === 'series') {
            return 'series';
        }
        return '';
    };

    let ImageElements = imageURLs.map((item, index) => (
        <div key={index} className={`image-wrapper ${getImageTypeClass(item.type)}`}>
            <span className="image-type">{item.type}</span>
            <img
                src={item.url}
                alt={`${index}`}
                className="image-card"
                onClick={() => handleImageClick(item)}
            />
        </div>
    ));

    return (
        <div>
            <div className="card-container">
                {ImageElements}
            </div>
            <ImageModal showModal={showModal} setShowModal={setShowModal} setSelectedImage={setSelectedImage}
                        selectedImage={selectedImage}/>
        </div>
    );
};

export default ImageGallery;
